import React, { useState } from "react";
import emailjs from "emailjs-com";
import axios from "axios";
import useFadeIn from "./components/useFadeIn";

function ContactSection() {
  const allFade = useFadeIn("fade-delay-1");

  const [formData, setFormData] = useState({
    apply: "",
    region: "",
    name: "",
    phone1: "010",
    phone2: "",
    phone3: "",
    revenue: "",
    service: {
      marketing: false,
      onlineMarketing: false,
      MSO: false,
    },
    memo: "",
    privacy: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox" && name.startsWith("service")) {
      setFormData({
        ...formData,
        service: {
          ...formData.service,
          [name.split("-")[1]]: checked,
        },
      });
    } else if (type === "checkbox") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.phone2.length !== 4 || formData.phone3.length !== 4) {
      alert("전화번호를 확인해주세요");
      return;
    } else if (!formData.privacy) {
      alert("개인정보처리방침에 동의해주세요");
      return;
    }

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAILJS_USER_ID;

    const templateParams = {
      apply: formData.apply,
      region: formData.region,
      name: formData.name,
      phone: `${formData.phone1}-${formData.phone2}-${formData.phone3}`,
      revenue: formData.revenue,
      service_marketing: formData.service.marketing ? "개원마케팅" : "",
      service_onlineMarketing: formData.service.onlineMarketing
        ? "온라인마케팅"
        : "",
      service_MSO: formData.service.MSO ? "MSO" : "",
      memo: formData.memo,
      privacy: formData.privacy ? "동의" : "비동의",
    };
    console.log(templateParams);
    try {
      await axios.post("/api/main/funnels", templateParams);
    } catch (error) {
      console.error("데이터 전송에 실패했습니다...", error);
      alert("데이터 전송에 실패했습니다. 전화 문의 부탁드립니다.");
    }
    try {
      await emailjs.send(serviceID, templateID, templateParams, userID);
      alert("최대한 빠른시간 내 연락드리겠습니다.");
    } catch (error) {
      console.error("메일 전송에 실패했습니다...", error);
      alert("메일 전송에 실패했습니다. 전화 문의 부탁드립니다.");
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <section
      id="contactSection"
      className="container"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/Images/10/bg_db.png)`,
      }}
    >
      <div
        ref={allFade.ref}
        className={`contact-section sectionInner ${allFade.className}`}
      >
        <div className="contact-info">
          <h2 className="fontEn">Contact us</h2>
          <ul>
            <li>
              <span>전화문의</span> 070-4495-2633
            </li>
            <li>
              <span>상담시간</span> 월-금 10시-17시 / 공휴일 휴무
            </li>
            <li>
              <span>문의이메일</span>{" "}
              <p className="fontEn">official@funnels.co.kr</p>
            </li>
          </ul>
        </div>
        <div className="form-section">
          <div className="form-group">
            <label>신청</label>
            <input
              type="radio"
              name="apply"
              value="의료인"
              checked={formData.apply === "의료인"}
              onChange={handleChange}
            />{" "}
            의료인
            <input
              type="radio"
              name="apply"
              value="광고대행사"
              checked={formData.apply === "광고대행사"}
              onChange={handleChange}
            />{" "}
            광고대행사
          </div>
          <div className="form-group">
            <label>지역</label>
            <select
              name="region"
              value={formData.region}
              onChange={handleChange}
            >
              <option value="">선택</option>
              <option value="서울">서울특별시</option>
              <option value="부산">부산광역시</option>
              <option value="대구">대구광역시</option>
              <option value="인천">인천광역시</option>
              <option value="광주">광주광역시</option>
              <option value="대전">대전광역시</option>
              <option value="울산">울산광역시</option>
              <option value="세종">세종특별자치시</option>
              <option value="경기">경기도</option>
              <option value="강원">강원도</option>
              <option value="충북">충청북도</option>
              <option value="충남">충청남도</option>
              <option value="전북">전라북도</option>
              <option value="전남">전라남도</option>
              <option value="경북">경상북도</option>
              <option value="경남">경상남도</option>
              <option value="제주">제주특별자치도</option>
            </select>
          </div>
          <div className="form-group">
            <label>이름</label>
            <input
              className="db_nametoka"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>전화번호</label>
            <div className="phone-input">
              <input
                type="text"
                name="phone1"
                value={formData.phone1}
                readOnly
                className="setnumberFirst"
                style={{
                  color: "#808080",
                  cursor: "not-allowed",
                  outline: "none",
                }}
              />
              <span>-</span>
              <input
                type="text"
                name="phone2"
                value={formData.phone2}
                onChange={handleChange}
                maxLength="4"
              />
              <span>-</span>
              <input
                type="text"
                name="phone3"
                value={formData.phone3}
                onChange={handleChange}
                maxLength="4"
              />
            </div>
          </div>
          <div className="form-group">
            <label>월 평균매출</label>
            <select
              name="revenue"
              value={formData.revenue}
              onChange={handleChange}
            >
              <option value="">선택</option>
              <option value="5천이하">5천이하</option>
              <option value="1억">1억</option>
              <option value="1.5억">1.5억</option>
              <option value="2억">2억</option>
              <option value="2.5억">2.5억</option>
              <option value="3억">3억</option>
              <option value="5억">5억</option>
              <option value="10억">10억</option>
            </select>
          </div>
          <div className="form-group">
            <label>문의서비스</label>
            <input
              type="checkbox"
              name="service-marketing"
              checked={formData.service.marketing}
              onChange={handleChange}
            />{" "}
            개원마케팅
            <input
              type="checkbox"
              name="service-onlineMarketing"
              checked={formData.service.onlineMarketing}
              onChange={handleChange}
            />{" "}
            온라인마케팅
            <input
              type="checkbox"
              name="service-MSO"
              checked={formData.service.MSO}
              onChange={handleChange}
            />{" "}
            MSO
          </div>
          <div className="form-group">
            <label>메모</label>
            <textarea
              name="memo"
              value={formData.memo}
              onChange={handleChange}
              placeholder="메모"
            ></textarea>
          </div>
          <div className="form-group privacy">
            <input
              type="checkbox"
              name="privacy"
              checked={formData.privacy}
              onChange={handleChange}
            />{" "}
            개인정보처리방침동의
            <button onClick={toggleModal}>[자세히보기]</button>
          </div>
          {isModalOpen && (
            <div className="privacyModal">
              <h2>■ 개인정보처리방침</h2>
              <p>개인정보 수집주체: 퍼널스</p>
              <p>개인정보 수집항목: 이름, 연락처, 상담내용</p>
              <p>
                개인정보 수집 및 이용목적: 퍼널스에서 예약 상담 활용(전화,문자)
              </p>
              <p>
                개인정보 보유 및 이용기간: 수집일로부터 6개월(고객 등의 철회시
                지체없이 파기)
              </p>
              <h2>■ 개인정보 취급 위탁</h2>
              <p>개인정보 취급 위탁을 받는 자: 퍼널스</p>
              <p>
                개인정보 취급위탁을 하는 업무의 내용: 홈페이지 운영 및 관리,
                이벤트 등 광고성 정보 전달, 고객정보 저장 및 서버관리
              </p>
              <p>
                *상기 동의를 거부할 관리가 있으며, 수집 및 이용에 동의하지 않을
                경우 퍼널스의 예약 상담 및 우대혜택이 불가능합니다.
              </p>
            </div>
          )}
          <button type="button" onClick={handleSubmit}>
            {`상담 / 문의하기`}
          </button>
        </div>
      </div>
    </section>
  );
}

export default ContactSection;
