import React from "react";

function MainSection() {
  return (
    <section id="MainSection" className="video-container">
      <video autoPlay loop muted playsInline className="background-video">
        <source
          src={`${process.env.PUBLIC_URL}/Images/mainSection/mainVideo.mp4`}
          type="video/mp4"
        />
        브라우저가 비디오 태그를 지원하지 않습니다.
      </video>
      <div className="content">
        <h2>치과 마케팅만 10년,</h2>
        <h3>결과는 다를 수 밖에 없습니다.</h3>
      </div>
    </section>
  );
}

export default MainSection;
