import TitleBox from "./components/TitleBox";
import useFadeIn from "./components/useFadeIn";

function TeamStructure() {
  const titleFade = useFadeIn("fade-delay-1");
  const teamFade = useFadeIn("fade-delay-2");

  const teamItems = [
    {
      img: "team1.png",
      title: "퍼포먼스 팀",
      desc: "온라인 DB 마케팅을 주축으로\n빠른 매출을 성장시키는 핵심 부서입니다",
    },
    {
      img: "team2.png",
      title: "CALL 팀",
      desc: "15년차 치위생사가 팀을 직접 이끌며,\n예약률 50% 이상을 유지하고 있습니다",
    },
    {
      img: "team3.png",
      title: "디자인 팀",
      desc: "치과 디자인만 전문으로 진행하며,\n수많은 디자인 레퍼런스를 보유하고 있습니다",
    },
    {
      img: "team4.png",
      title: "브랜드 팀",
      desc: "데이터 기반 그로스 해킹을 통해\n치과 가치를 극대화 시킵니다",
    },
    {
      img: "team5.png",
      title: "개발 팀",
      desc: "기공 관리 프로그램 “덴토탈”을\n자체 개발하여 운영 중 입니다",
    },
    {
      img: "team6.png",
      title: "재무/인사 팀",
      desc: "진료에만 집중할 수 있는\n경영환경을 만들어 드립니다",
    },
  ];

  return (
    <section id="TeamStructure">
      <div className="sectionInner">
        <div ref={titleFade.ref} className={titleFade.className}>
          <TitleBox
            subtitle="Team Funnels"
            title="당신의 치과가 성공할 수밖에 없는 이유"
            description="10년의 노하우를 축적한 파트별 전문가가 전담팀이 됩니다."
          />
        </div>
        <div
          className={`sectionInner teamGrid ${teamFade.className}`}
          ref={teamFade.ref}
        >
          {teamItems.map((item, index) => (
            <div className="teamCard" key={index}>
              <img
                src={`${process.env.PUBLIC_URL}/Images/teamStructure/${item.img}`}
                alt={item.title}
              />
              <h4>{item.title}</h4>
              <p>
                {item.desc.split("\n").map((line, i) => (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                ))}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
export default TeamStructure;
