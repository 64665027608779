import React, { useState } from "react";

import Carousel from "./Carousel";
import useFadeIn from "./components/useFadeIn";

const SlickSection = () => {
  const titleFade = useFadeIn("fade-delay-1");
  const phoneFade = useFadeIn("fade-delay-2");

  return (
    <section id="slickSection" className="">
      <div className="sectionInner">
        <div
          className={`section_titleBox ${titleFade.className}`}
          ref={titleFade.ref}
        >
          <h5 className="section_subtitle">What we do</h5>
          <h3 className="section_title">
            치과가 필요한 모든 서비스
            <br />
            퍼널스 솔루션
          </h3>
          <h4 className="section_description">
            DB · 플레이스 · 블로그 각개 대행사 관리? <br />
            퍼널스가 모든 서비스를 통합 제공합니다.
          </h4>
        </div>
        <div
          className={`slick-container ${phoneFade.className}`}
          ref={phoneFade.ref}
        >
          <Carousel />
        </div>
      </div>
    </section>
  );
};

export default SlickSection;
