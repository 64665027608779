import TitleBox from "./components/TitleBox";
import useFadeIn from "./components/useFadeIn";

function TextBanner({ scrollToContact }) {
  const titleFade = useFadeIn("fade-delay-1");

  return (
    <section id="TextBanner">
      <div className="sectionInner">
        <div ref={titleFade.ref} className={titleFade.className}>
          <div className="textBannerInner">
            <p>
              퍼널스는 매출로 증명합니다.
              <br />
              매출이 오르지 않는다면 환불해 드립니다.
            </p>
            <button>
              <a href="https://smore.im/form/NeXiVl6c43" target="_blank">
                우리 치과 점검 · 진단 무료 신청
              </a>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
export default TextBanner;
