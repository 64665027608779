import Slider from "react-slick";
import TitleBox from "./components/TitleBox";
import useFadeIn from "./components/useFadeIn";

function CustomerReviews() {
  const titleFade = useFadeIn("fade-delay-1");
  const reviewFade = useFadeIn("fade-delay-2");
  const reviews = [
    {
      img: "review1.png",
      name: "박*연 원장님",
      text: "데이터로 소통하니 믿음이 갑니다.",
    },
    {
      img: "review2.png",
      name: "추*욱 원장님",
      text: "병원 확장까지 고민입니다.",
    },
    {
      img: "review3.png",
      name: "이*희 원장님",
      text: "플레이스 매출 10배 상승했습니다.",
    },
    {
      img: "review4.png",
      name: "김*만 원장님",
      text: "병원 상호부터 함께 정했습니다.",
    },
    {
      img: "review5.png",
      name: "이*구 원장님",
      text: "환자 동의율이 월등히 상승했습니다.",
    },
  ];

  const CustomPrev = (props) => (
    <button className="customArrow prev" onClick={props.onClick}>
      <img
        src={`${process.env.PUBLIC_URL}/Images/reviews/rightBtn.png`}
        style={{ rotate: "180deg" }}
        alt="이전"
      />
    </button>
  );

  const CustomNext = (props) => (
    <button className="customArrow next" onClick={props.onClick}>
      <img
        src={`${process.env.PUBLIC_URL}/Images/reviews/rightBtn.png`}
        alt="다음"
      />
    </button>
  );

  const settings = {
    centerMode: true,
    centerPadding: "10%",
    slidesToShow: 3,
    infinite: true,
    prevArrow: <CustomPrev />,
    nextArrow: <CustomNext />,
    autoplay: true, // 자동 슬라이드
    autoplaySpeed: 1000, // 슬라이드 속도 (2초마다 슬라이드)
    speed: 1300, // 애니메이션 속도
    pauseOnHover: true, // hover 시 슬라이드 멈춤
  };

  return (
    <section id="CustomerReviews">
      <div className="sectionInner">
        <div ref={titleFade.ref} className={titleFade.className}>
          <TitleBox subtitle="Client with Funnels" title="실제 고객 후기" />
        </div>

        <div ref={reviewFade.ref} className={reviewFade.className}>
          <Slider {...settings} className="reviewSlider">
            {reviews.map((review, index) => (
              <div key={index} className="slideItem">
                <img
                  src={`${process.env.PUBLIC_URL}/Images/reviews/${review.img}`}
                  alt={`리뷰 ${index + 1}`}
                />
                <span className="reviewName">{review.name}</span>
                <p className="reviewText">{review.text}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default CustomerReviews;
