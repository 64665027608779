import React from "react";

const SlickCard = ({ imageKey, isActive }) => {
  return (
    <div className="card-container">
      <img
        className="bg-img"
        src={`${process.env.PUBLIC_URL}/Images/slick/${imageKey}.png`}
        alt={`${imageKey} background`}
      />
      {isActive && (
        <img
          className="top-img"
          src={`${process.env.PUBLIC_URL}/Images/slick/${imageKey}_1.png`}
          alt={`${imageKey} top`}
        />
      )}
    </div>
  );
};

export default SlickCard;
