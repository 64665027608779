import { useEffect, useState } from "react";
import Footer from "../../layout/Footer";
import Layout from "../../layout/Layout";
import Banner from "./MainSection";
import Spinner from "./Spinner";
import "../../style/style.scss";
import Modal from "./components/Modal";
import Contact from "./ContactSection";
import MainSection from "./MainSection";
import ContactSection from "./ContactSection";
import CardListSection from "./CardListSection";
import GraphCardSection from "./GraphCardSection";
import TextBanner from "./TextBanner";
import AnimatedFolder from "./AnimatedFolder";
import TeamStructure from "./TeamStructure";
import ServicePricing from "./ServicePricing";
import CustomerReviews from "./CustomerReviews";
import SlickSection from "./SlickSection";
import { animateScroll as scroll } from "react-scroll";

function Home() {
  const [isRendered, setIsRendered] = useState(false);
  const [isAppleDevice, setIsAppleDevice] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1120);

  const scrollToContact = () => {
    scroll.scrollTo(document.getElementById("contactSection").offsetTop);
  };

  // 여러 모달 상태 관리
  const [modals, setModals] = useState([
    {
      id: 1,
      top: 0,
      left: 0,
      isOpen: true,
      noShowPeriod: 7,
      imgSrc: "25011001.png",
      imgAlt: "모달 1 이미지",
      noShowImgSrc: "25011002.jpg",
      closeImgSrc: "25011003.jpg",
    },
  ]);

  // 모달 닫기 처리
  const handleClose = (id) => {
    setModals((prevModals) =>
      prevModals.map((modal) =>
        modal.id === id ? { ...modal, isOpen: false } : modal
      )
    );
  };

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const appleDevice = /iPhone|iPad|iPod|Macintosh/.test(userAgent);
    setIsAppleDevice(appleDevice);

    const timer = setTimeout(() => {
      setIsRendered(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (!isRendered) {
    return <Spinner />;
  }

  return (
    <div className={`mainHome ${isAppleDevice ? "iphone" : ""}`}>
      {/* {modals.map(
        (modal) =>
          modal.isOpen && (
            <Modal
              key={modal.id}
              top={modal.top}
              left={modal.left}
              id={modal.id}
              onClose={() => handleClose(modal.id)}
              noShowPeriod={modal.noShowPeriod}
              showAgainOption={modal.noShowPeriod > 0}
              imgSrc={process.env.PUBLIC_URL + "/Images/modal/" + modal.imgSrc}
              imgAlt={modal.imgAlt}
              closeImgSrc={
                process.env.PUBLIC_URL + "/Images/modal/" + modal.closeImgSrc
              }
              noShowImgSrc={
                process.env.PUBLIC_URL + "/Images/modal/" + modal.noShowImgSrc
              }
            />
          )
      )} */}
      <Layout />
      <MainSection />
      <CardListSection />
      <GraphCardSection />
      <TextBanner scrollToContact={scrollToContact} />
      <AnimatedFolder />
      <SlickSection />
      <TeamStructure />
      <CustomerReviews />
      <ServicePricing scrollToContact={scrollToContact} />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default Home;
