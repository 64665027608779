import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useRef, useState } from "react";
import TitleBox from "./components/TitleBox";
import useFadeIn from "./components/useFadeIn";

function CardListSection() {
  const titleFade = useFadeIn("fade-delay-1");

  const cardData = [
    {
      image: "card1.png",
      title: "DB 마케팅",
      description: (
        <>
          평균 신환 수<br />
          10배 증가
        </>
      ),
      fade: useFadeIn("fade-delay-2"),
      backgroundPosition: "0px -1.135px",
      backgroundSize: "100% 131.538%",
    },
    {
      image: "card2.png",
      title: "전문 TM 부서",
      description: (
        <>
          예약 · 해피콜 · CS
          <br />
          올인원 관리
        </>
      ),
      fade: useFadeIn("fade-delay-3"),
      backgroundPosition: "-238.517px 0px", // 각 카드에 맞는 값 설정
      backgroundSize: "210.854% 136.75% ",
    },
    {
      image: "card3.png",
      title: "상위노출 / 바이럴",
      description: (
        <>
          네이버 상위 <br />
          노출 영역 장악
        </>
      ),
      fade: useFadeIn("fade-delay-4"),
      backgroundPosition: "-170.103px 0px", // 각 카드에 맞는 값 설정
      backgroundSize: "206.176% 110.25%",
    },
  ];

  return (
    <section id="CardListSection">
      <div className="sectionInner">
        <div ref={titleFade.ref} className={titleFade.className}>
          <TitleBox
            subtitle="Funnels marketing group"
            title="매출을 보장하는 퍼널스의 성공 공식"
            description="전국 97여개 치과의 성공을 이끌었습니다."
          />
        </div>

        <div className="cardBox">
          {cardData.map((card, index) => (
            <div
              key={index}
              ref={card.fade.ref}
              className={`card ${card.fade.className}`}
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/Images/cardListSection/${card.image})`,
                backgroundPosition: card.backgroundPosition,
                backgroundSize: card.backgroundSize,
              }}
            >
              <p>{card.title}</p>
              <p>{card.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default CardListSection;
