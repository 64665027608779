import TitleBox from "./components/TitleBox";
import useFadeIn from "./components/useFadeIn";

function ServicePricing({ scrollToContact }) {
  const titleFade = useFadeIn("fade-delay-1");
  const planFade = useFadeIn("fade-delay-2");

  const planItems = [
    {
      title: "퍼포먼스 스탠다드",
      price: "1,500만원",
      summary: ["*신환 유입 중심", "*매출 극대화"],
      details: [
        "풀 퍼널 마케팅 분석",
        "DB 마케팅 · 광고 운영",
        "TM 예약 · 해피콜 · CS",
        "심의 대행 서비스",
        "랜딩 솔루션 임대",
        "네이버 상위 노출 전략",
        "마케팅 성과 리포트 제공",
      ],
    },
    {
      title: "프리미엄 프리미엄",
      price: "별도협의",
      summary: ["*신환 유입 · 매출 극대화", "*온 · 오프라인 종합 지원"],
      details: [
        "스탠다드 서비스 포함",
        "공식 채널 운영 · 관리",
        "오프라인 마케팅 지원",
        "매출 최적화 관리",
        "브랜딩",
      ],
    },
  ];

  return (
    <section id="ServicePricing">
      <div className="sectionInner">
        <div ref={titleFade.ref} className={titleFade.className}>
          <TitleBox
            subtitle="Funnels marketing service"
            title="퍼널스가 매출 성장의 길로 안내합니다"
          />
        </div>
        <div
          className={`plansWrapper ${planFade.className}`}
          ref={planFade.ref}
        >
          {planItems.map((plan, index) => (
            <div className="plan" key={index}>
              <h2 className="title">{plan.title}</h2>
              <div className="price">{plan.price}</div>
              <div className="summaryPoints">
                {plan.summary.map((point, i) => (
                  <p key={i} className="summaryPoint">
                    {point}
                  </p>
                ))}
              </div>
              <button className="ctaButton" onClick={scrollToContact}>
                문의하기
              </button>
              <ul className="detailsList">
                {plan.details.map((item, i) => (
                  <li key={i} className="detailItem">
                    ㆍ{item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
export default ServicePricing;
