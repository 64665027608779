import React, { useEffect, useState, useRef } from "react";
import TitleBox from "./components/TitleBox";
import useFadeIn from "./components/useFadeIn";

function GraphCardSection() {
  const titleFade = useFadeIn("fade-delay-1");
  const graphFade = useFadeIn("fade-delay-2");

  const [card1Data, setCard1Data] = useState({
    heights: [0, 0, 0, 0], // 초기 높이를 0으로 설정
    labels: ["", "", "", ""],
    dates: ["", "", "", ""],
  });
  const [card2Data, setCard2Data] = useState({
    heights: [0, 0, 0, 0],
    labels: ["", "", "", ""],
    dates: ["", "", "", ""],
  });
  const [zoomTarget, setZoomTarget] = useState("card1"); // card1이 초기 줌인 상태
  const [transitionDuration] = useState("0.4s");

  const timeoutRefs = useRef([]);

  const clearAnimationTimeouts = () => {
    timeoutRefs.current.forEach(clearTimeout);
    timeoutRefs.current = [];
  };

  const animateBars = (setData, data) => {
    clearAnimationTimeouts(); // 이전 애니메이션 타이머를 정리
    data.heights.forEach((height, index) => {
      const timeout = setTimeout(() => {
        setData((prev) => ({
          ...prev,
          heights: [
            ...prev.heights.slice(0, index),
            height,
            ...prev.heights.slice(index + 1),
          ],
          labels: [
            ...prev.labels.slice(0, index),
            data.labels[index],
            ...prev.labels.slice(index + 1),
          ],
          dates: [
            ...prev.dates.slice(0, index),
            data.dates[index],
            ...prev.dates.slice(index + 1),
          ],
        }));
      }, index * 100); // 각 막대의 애니메이션 시작을 100ms 간격으로 지연
      timeoutRefs.current.push(timeout);
    });
  };

  useEffect(() => {
    // card1 애니메이션 시작
    setTimeout(() => {
      animateBars(setCard1Data, {
        heights: [58, 85, 71, 130],
        labels: ["6억", "", "", "13억"],
        dates: ["22년 06월", "", "", "23년 12월"],
      });
      // card1 애니메이션이 끝난 후 card2 애니메이션 시작
      setTimeout(() => {
        resetData(setCard1Data);
        setZoomTarget("card2"); // 줌 타겟을 card2로 변경
        animateBars(setCard2Data, {
          heights: [30, 38, 76, 130],
          labels: ["1억", "", "", "7억"],
          dates: ["23년 02월", "", "", "24년 02월"],
        });
      }, 1100 + 100 * 4); // card1 애니메이션 시간 계산 후 시작
    }, 1200);
    return () => {
      clearAnimationTimeouts();
    };
  }, []);

  const resetData = (setData) => {
    setData((prev) => ({
      ...prev,
      heights: [0, 0, 0, 0],
    }));
  };

  const handleMouseEnter = (cardType) => {
    if (zoomTarget !== cardType) {
      // 이전 카드 데이터를 리셋
      if (zoomTarget === "card1") {
        resetData(setCard1Data);
      } else if (zoomTarget === "card2") {
        resetData(setCard2Data);
      }

      // 새 카드로 줌 타겟 변경
      setZoomTarget(cardType);

      // 새로운 카드 데이터로 애니메이션
      const cardData =
        cardType === "card1"
          ? {
              heights: [58, 85, 71, 130],
              labels: ["6억", "", "", "13억"],
              dates: ["22년 06월", "", "", "23년 12월"],
            }
          : {
              heights: [30, 38, 76, 130],
              labels: ["1억", "", "", "7억"],
              dates: ["23년 02월", "", "", "24년 02월"],
            };
      animateBars(cardType === "card1" ? setCard1Data : setCard2Data, cardData);
    }
  };

  return (
    <section id="graphCardSection" ref={graphFade.ref}>
      <div className="sectionInner">
        <div ref={titleFade.ref} className={titleFade.className}>
          <TitleBox
            subtitle="Funnels marketing"
            title="일반 마케팅 대행사는 절대 갖지 못할 기술력"
            description="퍼널스와 함께라면 원장님도 이런 변화를 만들어 낼 수 있습니다."
          />
        </div>
        <div className="graphCardBox">
          <div
            className={`card card1 ${zoomTarget === "card1" ? "zoomed" : ""}`}
            onMouseEnter={() => handleMouseEnter("card1")}
            style={{ zIndex: zoomTarget === "card1" ? 2 : 1 }}
          >
            <span>[온라인마케팅]</span>
            <span>수원 A치과</span>
            <p>
              온라인 마케팅 계약 후<br />월 매출 6억→13억
            </p>
            <div className="graphContainer">
              <div className="graph">
                {card1Data.heights.map((height, index) => (
                  <div
                    key={index}
                    className="bar"
                    style={{
                      height: `${height}%`,
                      transition: `height ${transitionDuration} ease-in-out`,
                      transitionDelay: `${index * 0.1}s`,
                    }}
                  >
                    <span>{card1Data.labels[index]}</span>
                    <p>{card1Data.dates[index]}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={`card card2 ${zoomTarget === "card2" ? "zoomed" : ""}`}
            onMouseEnter={() => handleMouseEnter("card2")}
            style={{ zIndex: zoomTarget === "card2" ? 10 : 1 }}
          >
            <span>[MSO]</span>
            <span>검단 B치과</span>
            <p>
              MSO 계약 14개월 만에
              <br />월 매출 7억 기록
            </p>
            <div className="graphContainer">
              <div className="graph">
                {card2Data.heights.map((height, index) => (
                  <div
                    key={index}
                    className="bar"
                    style={{
                      height: `${height}%`,
                      transition: `height ${transitionDuration} ease-in-out`,
                      transitionDelay: `${index * 0.1}s`,
                    }}
                  >
                    <span>{card2Data.labels[index]}</span>
                    <p>{card2Data.dates[index]}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GraphCardSection;
