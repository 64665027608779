function TitleBox({ subtitle, title, description }) {
  return (
    <div className="section_titleBox">
      {subtitle && <h5 className="section_subtitle">{subtitle}</h5>}
      {title && <h3 className="section_title">{title}</h3>}
      {description && <h4 className="section_description">{description}</h4>}
    </div>
  );
}

export default TitleBox;
