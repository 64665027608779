import { useEffect, useRef, useState } from "react";
import TitleBox from "./components/TitleBox";
import useFadeIn from "./components/useFadeIn";

function AnimatedFolder() {
  const titleFade = useFadeIn("fade-delay-1");
  const folderFade = useFadeIn("fade-delay-2");
  const textFade = useFadeIn("fade-delay-3");

  const folderImages = Array.from(
    { length: 8 },
    (_, i) => `folder${i + 1}.png`
  );

  const sectionRef = useRef(null);
  const fourthImgRef = useRef(null);
  const prevScrollY = useRef(window.scrollY);

  const [scrolledUp, setScrolledUp] = useState(false);
  const [initialMoved, setInitialMoved] = useState(false);

  // ✅ 섹션 진입 감지 → 최초 진입 시 애니메이션 실행
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting && !initialMoved) {
  //         const img = fourthImgRef.current;
  //         if (!img) return;

  //         img.style.transition = "transform 0.6s ease";
  //         img.style.transform = "translateY(0)";

  //         // 다음 프레임에 올라갔다가
  //         requestAnimationFrame(() => {
  //           img.style.transform = "translateY(-50px)";

  //           setTimeout(() => {
  //             img.style.transform = "translateY(0)";
  //           }, 600);

  //           setTimeout(() => {
  //             setInitialMoved(true); // ✅ 이후 스크롤 이벤트 활성화
  //           }, 1200);
  //         });
  //       }
  //     },
  //     {
  //       threshold: 0.5, // 40% 정도 보이면 실행
  //     }
  //   );

  //   if (sectionRef.current) {
  //     observer.observe(sectionRef.current);
  //   }

  //   return () => {
  //     if (sectionRef.current) observer.unobserve(sectionRef.current);
  //   };
  // }, [initialMoved]);

  // ✅ 이후 스크롤 감지
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (!initialMoved) return;

  //     const currentY = window.scrollY;
  //     const isScrollUp = currentY < prevScrollY.current;
  //     prevScrollY.current = currentY;

  //     setScrolledUp(isScrollUp);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [initialMoved]);

  return (
    <section id="AnimatedFolder" ref={sectionRef}>
      <div className="sectionInner">
        <div ref={titleFade.ref} className={titleFade.className}>
          <TitleBox
            subtitle="MSOM"
            title="단순 광고대행만 해본 회사는 치과의 니즈를 파악할 수 없습니다"
          />
        </div>

        <div
          className={`folderStack ${folderFade.className}`}
          ref={folderFade.ref}
        >
          {folderImages.map((fileName, index) => {
            const isFourth = index === 3;
            return (
              <img
                key={index}
                ref={isFourth ? fourthImgRef : null}
                src={`${process.env.PUBLIC_URL}/Images/animatedFolder/${fileName}`}
                alt={`folder${index + 1}`}
                className={`folderImage ${isFourth ? "fourth" : ""}`}
                style={{
                  left: `calc(${index * 40}px + 25%)`,
                  bottom: `${index * 30}px`,
                  zIndex: folderImages.length - index,
                  // transform:
                  //   isFourth && initialMoved && scrolledUp
                  //     ? "translateY(-50px)"
                  //     : "translateY(0)",
                  transform: isFourth ? "translateY(-50px)" : "translateY(0)",
                  transition: isFourth ? "transform 0.3s ease" : undefined,
                }}
              />
            );
          })}
        </div>

        <p ref={textFade.ref} className={textFade.className}>
          퍼널스는 MSO를 운영하며 축적한 치과에 대한 깊은 이해를 온라인 마케팅에
          접목하여,
          <br /> 독자적인 철학인 MSOM (Management Service and Online
          Marketing)을 만들어냈습니다.
        </p>
      </div>
    </section>
  );
}

export default AnimatedFolder;
