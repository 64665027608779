import React, { useEffect, useState } from "react";
import SlickCard from "./components/SlickCard";

const images = ["db", "tm", "viral", "brand", "place", "medical"];

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [startX, setStartX] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prev) => (prev + 1) % images.length);
    }, 2200);

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 정리
  }, []);

  const prevSlide = () => {
    setActiveIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  const nextSlide = () => {
    setActiveIndex((prev) => (prev + 1) % images.length);
  };

  const getClass = (index) => {
    if (index === activeIndex) return "card active";
    if (index === (activeIndex - 1 + images.length) % images.length)
      return "card left";
    if (index === (activeIndex + 1) % images.length) return "card right";
    return "card hidden";
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    if (startX === null) return;
    const endX = e.changedTouches[0].clientX;
    const diff = startX - endX;
    if (diff > 50) nextSlide();
    else if (diff < -50) prevSlide();
    setStartX(null);
  };

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    setIsDragging(true);
  };

  const handleMouseUp = (e) => {
    if (!isDragging || startX === null) return;
    const endX = e.clientX;
    const diff = startX - endX;
    if (diff > 50) nextSlide();
    else if (diff < -50) prevSlide();
    setIsDragging(false);
    setStartX(null);
  };

  return (
    <div className="carousel-container">
      <button className="nav prev custom-arrow" onClick={prevSlide}>
        <span className="arrow-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="20"
            viewBox="0 0 11 20"
            fill="none"
          >
            <path
              d="M8.95995 19.9948L0.732454 11.7673C0.263777 11.2984 0.000488281 10.6627 0.000488281 9.99977C0.000488281 9.33686 0.263777 8.70109 0.732454 8.23227L8.95495 0.00976562L10.1333 1.1881L1.91079 9.4106C1.75456 9.56687 1.6668 9.7788 1.6668 9.99977C1.6668 10.2207 1.75456 10.4327 1.91079 10.5889L10.1383 18.8164L8.95995 19.9948Z"
              fill="#919191"
            />
          </svg>
        </span>
      </button>
      <div
        className="carousel"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {images.map((imageKey, index) => (
          <div key={imageKey} className={getClass(index)}>
            <div className="card-inner">
              <SlickCard imageKey={imageKey} isActive={index === activeIndex} />
            </div>
          </div>
        ))}
      </div>
      <button className="nav next custom-arrow" onClick={nextSlide}>
        <span className="arrow-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="20"
            viewBox="0 0 11 20"
            fill="none"
          >
            <path
              d="M2.04005 0.00523298L10.2675 8.23273C10.7362 8.70155 10.9995 9.33732 10.9995 10.0002C10.9995 10.6631 10.7362 11.2989 10.2675 11.7677L2.04505 19.9902L0.866713 18.8119L9.08921 10.5894C9.24544 10.4331 9.3332 10.2212 9.3332 10.0002C9.3332 9.77926 9.24544 9.56734 9.08921 9.41107L0.861714 1.18357L2.04005 0.00523298Z"
              fill="#919191"
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

export default Carousel;
